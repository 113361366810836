import React, { useState } from 'react';
import ExaminerTier from './ExaminerTier';

import { PER_EXAMINER_PRO_PRICES } from './prices';

import TeamsTier from './TeamsTier';

const ExaminerPricingTier = ({ currentlySetFrequency, isSlpImpact = false }: any) => {
  const [numberOfExaminers, setNumberOfExaminers] = useState(PER_EXAMINER_PRO_PRICES[0].id);

  return (
    <ExaminerTier
      numberOfExaminers={numberOfExaminers}
      setNumberOfExaminers={setNumberOfExaminers}
      perSeatPrices={PER_EXAMINER_PRO_PRICES}
      currentlySetFrequency={currentlySetFrequency}
      isSlpImpact={isSlpImpact}
    />
  );
};

export default ExaminerPricingTier;
