import React, { useState } from 'react';
import ExaminerTier from './ExaminerTier';

import { PER_EXAMINER_POWER_PRICES } from './prices';

import TeamsTier from './TeamsTier';
import ExaminerPremiumTier from './ExaminerPremiumTier';

const ExaminerPremiumPricingTier = ({ currentlySetFrequency, isSlpImpact = false }: any) => {
  const [numberOfExaminers, setNumberOfExaminers] = useState(PER_EXAMINER_POWER_PRICES[0].id);

  return (
    <ExaminerPremiumTier
      numberOfExaminers={numberOfExaminers}
      setNumberOfExaminers={setNumberOfExaminers}
      perSeatPrices={PER_EXAMINER_POWER_PRICES}
      currentlySetFrequency={currentlySetFrequency}
      isSlpImpact={isSlpImpact}
    />
  );
};

export default ExaminerPremiumPricingTier;
