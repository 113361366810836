import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { SEO, Headline, Lead } from '../components';
import { TeamsPricingTier, FeatureComparisonTable } from '../components/pricing';
import { FAQs } from '../components/landing';
import ExaminerPricingTier from '../components/pricing/ExaminerPricingTier';
import Logo from '../components/Logo';

const Pricing = () => {
  const [currentlySetFrequency, setFrequency] = useState('Annual');

  return (
    <>
      <div className="flex justify-start lg:w-0 lg:flex-1 top-0 left-0  px-8 py-6 absolute">
        <Logo />
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <div className="sm:text-center">
              <Headline el="h2">Choose a plan that works for you</Headline>
              <div className="mt-5">
                <Lead>Examiner effectiveness for teams of all sizes</Lead>
              </div>
            </div>

            {/* <BillingFrequencySwitcher
              setFrequency={setFrequency}
              currentlySetFrequency={currentlySetFrequency}
            /> */}
          </div>

          <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto">
            <ExaminerPricingTier currentlySetFrequency={currentlySetFrequency} />
            <TeamsPricingTier />
          </div>
        </div>

        <div className="max-w-4xl mx-auto py-16 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center mb-20">
            <Headline el="h2" size="small">
              Plan comparison
            </Headline>
          </div>
          <FeatureComparisonTable currentlySetFrequency={undefined} />
        </div>
      </div>

      <FAQs />
    </>
  );
};

export default Pricing;

export const Head = () => (
  <>
    <SEO
      title={`Select Plan`}
      description="Evalubox plans. Simple, flexible pricing options to fit your evaluations needs. Try for Free."
    />
  </>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
