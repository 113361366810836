import React from 'react';

import TierBulletsSection from './TierBulletsSection';

const ExaminerPremiumTierBenefits = () => (
  <>
    <TierBulletsSection
      hasIcon={false}
      bullets={[
        {
          title: 'Up to 120 Reports Annually',
        },
        // {
        //   title: '360 Evaluation Records*',
        // },
        // {
        //   title: '600 Digital Forms',
        // },
      ]}
    />
  </>
);

export default ExaminerPremiumTierBenefits;
