const TIERS = {
  pro: {
    name: 'SLP Pro',
    minSeats: 30,
    description:
      'For individual SLPs who fits multiple evaluations and writing into windows between therapy and meetings.',
    ctaLabel: 'Get Started',
    ctaLinkTo: '/get-started/?plan=pro',
    planId: 'individual-examiner-professional',
    ctaLinkToSlpImpact: '/request-access/',
    monthly: '15.00',
    annual: '12.50',
  },
  premium: {
    name: 'Power Evaluator',
    minSeats: 120,
    description:
      'For evaluating SLPs who spends the majority of their work week conducting assessments.',
    ctaLabel: 'Get Started',
    planId: 'individual-examiner-premium',
    ctaLinkTo: '/get-started/?plan=premium',
    ctaLinkToSlpImpact: '/slp-impact/request-demo/',
    monthly: '38.70',
    annual: '29.77',
  },
  teams: {
    name: 'Teams',
    minSeats: 150,
    description:
      'For leaders to reduce their team’s workload with tools for tracking and writing great reports in less time.',
    ctaLabel: 'Request a Demo',
    ctaLinkTo: '/request-demo/',
    ctaLinkToSlpImpact: '/slp-impact/request-demo/',
  },
  forms: {
    name: 'Evalubox Forms - Early Adopter Plan',
    minSeats: 150,
    description:
      'For individual examiners or teams who want to gather information faster, track progress, and manage compliance.',
    ctaLabel: 'Join Waitlist',
    ctaLinkTo: '#solutions',
    ctaLinkToSlpImpact: '/slp-impact/request-demo/',
    monthly: '12',
    annual: '7',
  },
};

export default TIERS;
