import React from 'react';

import { USD_TO_EUR_EXCHANGE_RATE } from '../../components/pricing/prices';
import { NumberOfEngineers } from '../../components';
import TIERS from './tiers';
const TeamsTierPriceDisplay = () => (
  <>
    <p className="text-4xl font-extrabold text-gray-900">Custom</p>

    <div className="mb-10">
      <p className="pt-4 pb-4">
        150 evaluations or more <br />
      </p>
      {/* <span className="block w-fit text-xs pt-2">
        Includes 150 evaluations. Additional evaluations are sold in increments of 10 from $00.99
        per evaluation/month
      </span> */}
    </div>
  </>
);

export default TeamsTierPriceDisplay;
