import React from 'react';
import { Button, HelpText } from '..';

import TeamsTierPriceDisplay from './TeamsTierPriceDisplay';
import TeamsTierBenefits from './TeamsTierBenefits';
import TierContainer from './TierContainer';
import TierName from './TierName';
import TierDescription from './TierDescription';
import TIERS from './tiers';
import ExaminerTierBenefits from './ExaminerTierBenefits';
import ExaminerTierPriceDisplay from './ExaminerTierPriceDisplay';

const ctaComponent = (currentlySetFrequency) => (
  <Button
    text={TIERS.pro.ctaLabel}
    link={true}
    color="primary"
    to={
      currentlySetFrequency === 'Monthly'
        ? `${TIERS.pro.ctaLinkTo}&frequency=Monthly`
        : `${TIERS.pro.ctaLinkTo}&frequency=Annual`
    }
    // to={TIERS.pro.ctaLinkTo}
    onClick={() =>
      window.analytics &&
      window.analytics.track('Selected Plan', {
        plan: TIERS.pro.planId,
        frequency: currentlySetFrequency,
      })
    }
    fullWidth
  />
);
const ExaminerTier = ({
  ctaHelpText = 'Campus or Clinic SLP',
  // ctaComponent = (
  //   <Button
  //     text={TIERS.pro.ctaLabel}
  //     link={true}
  //     color="primary"
  //     to={currentlySetFrequency === 'Monthly' ? `${TIERS.pro.ctaLinkTo}-monthly` : `${TIERS.pro.ctaLinkTo}-annual`}
  //     // to={TIERS.pro.ctaLinkTo}
  //     fullWidth
  //   />
  // ),
  perSeatPrices,
  numberOfExaminers,
  setNumberOfExaminers,
  currentlySetFrequency,
  isSlpImpact,
}: any) => (
  <TierContainer>
    <div className="p-6">
      <TierName name={TIERS.pro.name} />
      <TierDescription description={TIERS.pro.description} />

      <ExaminerTierPriceDisplay
        perSeatPrices={perSeatPrices}
        numberOfExaminers={numberOfExaminers}
        setNumberOfExaminers={setNumberOfExaminers}
        currentlySetFrequency={currentlySetFrequency}
        isSlpImpact={isSlpImpact}
      />

      <div>
        <div className="mb-1">{ctaComponent(currentlySetFrequency)}</div>

        <div className="text-center">
          <HelpText message={ctaHelpText} />
        </div>
      </div>
    </div>

    <ExaminerTierBenefits />
  </TierContainer>
);

export default ExaminerTier;
