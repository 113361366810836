import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { SEO, SitewideHeader, SitewideFooter } from '../components';
import { SidebarTestimonial } from '../components/landing';
import { SubmissionSuccessModal } from '../components/FormSubmissionModal';
import { SCM_TOOLS } from '../components/forms/ScmToolRadioGroup';

import laurenAvatar from '../../content/assets/home/testimonial/lauren-bilinguistics/avatar.webp';
import laurenAvatarJpeg from '../../content/assets/home/testimonial/lauren-bilinguistics/avatar.jpg';
import { FormWithLeftSidebar } from '../components/layouts';
import DemoForm from '../components/forms/DemoForm';

const SubmissionSuccessPositiveBody = () => (
  <p>Thank you for contacting Evalubox. We&apos;ll be in touch via the email provided.</p>
);

const SubmissionSuccessNegativeBody = () => (
  <>
    <p>Evalubox only supports teams and individual examiners for now.</p>
    <p>
      We are working to support more tools in the near future. You will be among the first to know
      when we support yours.
    </p>
  </>
);

const ContactSales = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  const [modalOpen, setModalOpen] = useState(false);
  const [scmTool, setScmTool] = useState(SCM_TOOLS[0].value);

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <SubmissionSuccessModal
        scmTool={scmTool}
        handleCloseModal={handleCloseModal}
        modalOpen={modalOpen}
        siteMetadata={data.site.siteMetadata}
        positiveTitle="We'll be in touch!"
        positiveBody={<SubmissionSuccessPositiveBody />}
        negativeTitle="Oops! We're not ready for you yet."
        negativeBody={<SubmissionSuccessNegativeBody />}
        followOn="CONFIRM_DEMO"
      />

      <div className="min-h-screen bg-white">
        <SitewideHeader />
        <FormWithLeftSidebar
          title="Contact Evalubox"
          description="Find out how you can maximize the use of Evalubox in your organization.."
          sidebarChildren={
            <SidebarTestimonial
              quote="Evalubox has been an excellent guide for me as an SLP that is new to school based evaluations. 
              Coming from a clinic setting, I was a bit nervous about making sure all necessary information was included in an organized way in my reports. This tool has been very helpful in completing thorough, organized evaluations in a reasonable amount of time. "
              avatar={{
                webp: laurenAvatar,
                png: laurenAvatarJpeg,
                alt: 'Headshot of a womain facing the camera.',
              }}
              quoteeName="Lauren Hudgins, M.S., CCC-SLP"
              quoteeRole="Speech Language Pathologist"
            />
          }
        >
          <DemoForm
            onSuccess={() => {
              setModalOpen(true);
            }}
          />
        </FormWithLeftSidebar>
        <SitewideFooter />
      </div>
    </>
  );
};

export default ContactSales;

const title = 'Lets talk and see how we can help';
const description = 'See the Evalubox experience first hand and asks us any questions you may have';
const SEO_TITLE = 'Contact Us';
export const Head = () => (
  <>
    <SEO
      title={`${SEO_TITLE}`}
      description="Find out how you can maximize the use of Evalubox in your organization."
    />
  </>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
