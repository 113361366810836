import React from 'react';

import TierBulletsSection from './TierBulletsSection';

const TeamsTierBenefits = () => (
  <>
    <TierBulletsSection
      hasIcon={false}
      bullets={[
        {
          title: '150+ Reports',
        },
        // {
        //   title: 'Unlimited Evaluation Records*',
        // },
        // {
        //   title: 'Unlimited Digital Forms',
        // },
      ]}
    />
  </>
);

export default TeamsTierBenefits;
