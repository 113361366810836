import React from 'react';
import { Lead, Headline } from '../../components';

const SectionHeader = ({ headline = 'Plans & Pricing' }: any) => (
  <div className="sm:text-center">
    <Headline el="h2">{headline}</Headline>
    <div className="mt-5">
      <Lead>
        Less writing time, fewer errors, with your favorite templates and blurbs in one place!
      </Lead>
    </div>
  </div>
);

export default SectionHeader;
