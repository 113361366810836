import React, { createRef, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Form, Field, ErrorMessage, useFormik, FormikProvider } from 'formik';

import { ChargebeeCard } from './CheckoutForm';
import Loader from '../Loader/loader';
import { DateTime } from 'luxon';
import axios from 'axios';
import { navigate } from 'gatsby';
import Link from '../Link';
import TIERS from '../pricing/tiers';
import { SubmissionSuccessModal } from '../FormSubmissionModal';
import { checkoutUrl } from '../../environment';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const urlEncode = function (data) {
  var str = [];
  for (var p in data) {
    if (data.hasOwnProperty(p) && !(data[p] == undefined || data[p] == null)) {
      str.push(encodeURIComponent(p) + '=' + (data[p] ? encodeURIComponent(data[p]) : ''));
    }
  }
  return str.join('&');
};

const SubmissionSuccessPositiveBody = () => (
  <p>Thank you for requesting a demo of Evalubox. We&apos;ll be in touch via the email provided.</p>
);

const SubmissionSuccessNegativeBody = () => (
  <>
    <p>
      Please try again. If issue still persist, please contact us at admin@evalubox.com and We can
      help you set up your Evalubox account.
    </p>
    {/* <InlineWidget
      styles={{
        height: '500px',
      }}
      url="https://calendly.com/evalubox/evalubox-sales?hide_event_type_details=1&hide_gdpr_banner=1"
    /> */}
  </>
);
const isBrowser = () => typeof window !== 'undefined';

const BasicCheckoutForm = ({
  cbInstance,
  buyNow,
  isSlpImpact,
  trialDays,
  plan,
  frequency,
}: any) => {
  const params = new URLSearchParams(isBrowser() && location.search);
  // const planFromUrl = decodeURIComponent(params.get('plan') || '');
  // const cycleFromUrl = decodeURIComponent(params.get('cycle') || 'annual');
  const cardRef = createRef<any>();
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [error, setError] = useState('');
  const [complete, setComplete] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [scmTool, setScmTool] = useState();
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const planName = {
    pro: `individual-examiner-professional-${frequency.toLowerCase()}`,
    premium: `individual-examiner-premium-${frequency.toLowerCase()}`,
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is a required field'),
    lastName: Yup.string().required('Last name is a required field'),
    orgName: Yup.string().required('Organization name is a required field'),
    email: Yup.string().email().required('Email is a required field'),
    credentials: Yup.string().required('Credentials is a required field'),
    discipline: Yup.string().required('Discipline is a required field'),
  });
  const initialValues = {
    firstName: '',
    lastName: '',
    orgName: '',
    email: '',
    credentials: '',
    discipline: '',
    billingCycle: frequency.toLowerCase(),
    cbToken: '',
    captchaToken: '',
    planId: planName[plan],
    buyNow: buyNow, //Set up backedn to process payment right away if buyNow is true
  };

  const trackSignup = (values) => {
    window.analytics.track('Signed Up', values);
  };

  const trackSignupFailure = (values) => {
    window.analytics.track('Signed Up Failed', values);
  };
  //declaration of onSubmit callback
  const onSubmit = async (values) => {
    // console.log("values", values)
    values.buyNow = buyNow.toString();
    // if (isSlpImpact) {
    //   values.isSlpImpact = isSlpImpact.toString();
    // }

    try {
      const response = await axios
        .post(checkoutUrl(), urlEncode(values))
        .then((response) => response)
        .catch((error) => {
          // console.log("error", error)
          throw new Error('Error');
        });
      // console.log("response", response)
      if (response.status === 200) {
        try {
          trackSignup(values);
        } catch (error) {}
        navigate('/account/verify');
      } else {
        throw new Error('Unable to create account');
      }
      // alert(JSON.stringify(values, null, 2));
    } catch (error) {
      throw new Error('Unable to create account');
    }
  };

  const tokenize = () => {
    return cardRef.current
      .tokenize({})
      .then(async (data) => {
        setLoading(true);
        setToken(data.token);
        setError('');
        // console.log("token", token)
        return data.token;
      })
      .catch((error) => {
        setComplete(false);
        // console.log("token error", error)
        setToken('');
        setError('Problem validating your card details');
        throw new Error('Problem while tokenizing your card details');
      });
  };

  const renderError = (message) => <p className="help is-danger text-red-400 text-xs">{message}</p>;
  const [ready, setReady] = useState(false);
  const [hideChargebee, setHideChargebee] = useState(false);
  const onReady = (el) => {
    // el.focus();
    setReady(true);
  };

  useEffect(() => {
    if (loading) {
      // Make API call to /beer
      formik.submitForm();
    } else {
      // Throw error 404, beer not found
    }
  }, [loading]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values: any, { setErrors, setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        const token = await tokenize();
        values.cbToken = token;
        await onSubmit(values);
      } catch (error) {
        try {
          trackSignupFailure(values);
        } catch (error) {}
        setHideChargebee(true);
        setModalOpen(true);
      } finally {
        setLoading(false);
        await resetForm();
        setHideChargebee(false);
      }
    },
  });
  // });

  // initialValues: {
  //   examiners: initialValues.examiners && [
  //     ...initialValues.examiners.map((item) => item.userId),
  //   ],
  // },
  // onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
  //   try {
  //     const { examiners }: any = values;
  //     const initEqual = _.isEqual(examiners, initialValues.examiners);
  //     if (!initEqual && formik.dirty) {
  //       await editEvaluationExaminer({ examiners: examiners });
  //       successNoty({
  //         msg: "Successfully assigned examiner.",
  //       });
  //       resetForm({ values });
  //     }
  //   } catch (e) {
  //     setErrors(e);
  //     errorNoty({
  //       msg: "Unable to assign examiners at this time. Please refresh your page and try again. Contact us if issue still persists.",
  //       timeout: 10000,
  //     });
  //   } finally {
  //     setSubmitting(false);
  //   }
  // },
  // });

  return (
    <>
      <SubmissionSuccessModal
        scmTool={scmTool}
        handleCloseModal={handleCloseModal}
        modalOpen={modalOpen}
        // siteMetadata=""
        positiveTitle="We'll be in touch!"
        positiveBody={<SubmissionSuccessPositiveBody />}
        negativeTitle="Oops! We were not able to complete your request."
        negativeBody={<SubmissionSuccessNegativeBody />}
      />

      {/* <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            const token = await tokenize();
            values.cbToken = token;
            await onSubmit(values);
          } catch (error) {
            setHideChargebee(true);
            setModalOpen(true);
          } finally {
            setLoading(false);
            await resetForm();
            setHideChargebee(false);
          }
        }}
      > */}
      <FormikProvider value={formik}>
        {/* {({ errors, status, touched, isValid, dirty }) => ( */}
        <Form className="tracking-wide">
          <ol>
            <li>
              <div className="text-xl text-black  ">
                {' '}
                Step 1: Create an account.
                <Link
                  to="https://app.evalubox.com/evaluations"
                  className="text-sm text-blue-600 hover:underline"
                >
                  {' '}
                  Have a Evalubox account? Log in
                </Link>
              </div>
              <AccountForm renderError={renderError} />
            </li>

            <li>
              <div className="text-xl text-black pt-4 "> Step 2: Payment method</div>

              <div className="grid grid-cols-6 gap-4 text-stai-1">
                <div className="col-span-6">
                  <legend className="mb-1 block text-sm text-gray-600 flex justify-between w-full">
                    Card Details *
                    <span className=" text-sm flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                        />
                      </svg>
                      <span className="px-1">Secure Checkout</span>
                    </span>
                  </legend>
                  {!ready && <>Loading secure checkout ...</>}
                  {!hideChargebee && (
                    <ChargebeeCard
                      cbInstance={cbInstance}
                      cardRef={cardRef}
                      complete={complete}
                      setComplete={setComplete}
                      onReady={onReady}
                    />
                  )}
                </div>
              </div>
            </li>

            <li>
              <div className="text-xl text-black pt-3 "> Today's Total</div>
              {/* {buyNow ? ( */}
              <TotalAfterTrial
                cycle="annual"
                buyNow={buyNow}
                isSlpImpact={isSlpImpact}
                trialDays={trialDays}
                plan={plan}
                frequency={frequency}
              />
              {/* ) : (
                <TodaysToday />
              )} */}
            </li>
            {/* {!buyNow && (
              <li>
                <div className="text-2xl text-black pt-3 "> Total After Trial</div>
                <TotalAfterTrial cycle="annual" isSlpImpact={isSlpImpact} trialDays={trialDays} />
              </li>
            )} */}
            <li>
              <div className="col-span-6 py-2">
                <button
                  className={`disabled:cursor-not-allowed disabled:opacity-30 block w-full rounded-lg bg-stai-2 p-2.5 font-bold text-base text-white`}
                  type="button"
                  onClick={() => setLoading(true)}
                  disabled={!(formik.isValid && formik.dirty && complete && !loading)}
                >
                  {loading ? <Loader /> : buyNow ? 'Subscribe' : 'Start Trial'}
                </button>
              </div>
            </li>
          </ol>
        </Form>
        {/* )} */}
      </FormikProvider>
      {/* </Formik> */}
    </>
  );
};

export default BasicCheckoutForm;

const AccountForm = ({ renderError, isSlpImpact }: any) => {
  return (
    <div className="grid grid-cols-6 gap-4 text-stai-1">
      <div className="md:col-span-3 lg:col-span-3 col-span-6">
        <label className="mb-1 block text-sm text-gray-600" htmlFor="firstName">
          First Name *
        </label>
        <div className="control">
          <Field
            name="firstName"
            type="text"
            className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
            // placeholder="First name"
          />
          <ErrorMessage name="firstName" render={renderError} />
        </div>
      </div>
      <div className="md:col-span-3 lg:col-span-3 col-span-6">
        <label className="mb-1 block text-sm text-gray-600" htmlFor="lastName">
          Last Name *
        </label>
        <div className="control">
          <Field
            name="lastName"
            type="text"
            className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
            // placeholder="Last name"
          />
          <ErrorMessage name="lastName" render={renderError} />
        </div>
      </div>
      <div className="col-span-6">
        <label className="mb-1 block text-sm text-gray-600" htmlFor="email">
          Email address *
        </label>
        <div className="control">
          <Field
            name="email"
            type="email"
            className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
            // placeholder="Email address"
          />
          <ErrorMessage name="email" render={renderError} />
        </div>
      </div>
      <div className="col-span-6">
        <label className="mb-1 block text-sm text-gray-600" htmlFor="orgName">
          Organizations Name{' '}
          <span className="text-xs">(School district, Clinic, or Company name) *</span>
        </label>
        <div className="control">
          <Field
            name="orgName"
            type="text"
            className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
            // placeholder="Organization name"
          />
          <ErrorMessage name="orgName" render={renderError} />
        </div>
      </div>
      <div className="md:col-span-3 lg:col-span-3 col-span-6">
        <label className="mb-1 block text-sm text-gray-600" htmlFor="credentials">
          Credentials <span className="text-xs">(M.S., M.A., Ph.D.) *</span>
        </label>
        <div className="control">
          <Field
            name="credentials"
            type="text"
            className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
            // placeholder="First name"
          />
          <ErrorMessage name="credentials" render={renderError} />
        </div>
      </div>
      <div className="md:col-span-3 lg:col-span-3 col-span-6">
        <label className="mb-1 block text-sm text-gray-600" htmlFor="discipline">
          Discipline <span className="text-xs">(CCC-SLP) *</span>
        </label>
        <div className="control">
          <Field
            name="discipline"
            type="text"
            className="w-full rounded-lg border p-3.5 text-sm shadow-sm"
            // placeholder="Last name"
          />
          <ErrorMessage name="discipline" render={renderError} />
        </div>
      </div>
    </div>
  );
};

const TodaysToday = () => {
  return (
    <div className="col-span-6">
      <p className="flex">
        <span className="text-3xl font-bold tracking-wide">
          $0.00
          <span className="text-xs tracking-wide align-bottom font-normal px-2">
            Cancel trial anytime.
          </span>
        </span>
        {/* <span className="text-xs tracking-wide align-bottom">Cancel trial anytime.</span> */}
      </p>
    </div>
  );
};

const TotalAfterTrial = ({ cycle, buyNow, isSlpImpact, trialDays, plan, frequency }: any) => {
  const trialEndDate = DateTime.now().plus({ days: trialDays }).toLocaleString(DateTime.DATE_MED);
  const trialEndDay = DateTime.now().plus({ days: trialDays }).weekdayShort;
  return (
    <div className="col-span-6">
      <p className="pb-2">
        <span className="text-3xl font-bold tracking-wide">
          $
          {isBrowser() &&
            (
              TIERS[plan][frequency.toLowerCase()] * (frequency.toLowerCase() === 'annual' ? 12 : 1)
            ).toFixed(2)}
          {/* {isSlpImpact && (
            <span className="text-sm text-gray-500 pb-4 font-light tracking-widest line-through">
              {TIERS.pro.annual}
            </span>
          )} */}
        </span>{' '}
        <br />
        {frequency.toLowerCase() === 'annual' && (
          <span className="text-sm text-gray-500 pb-4">
            ${isBrowser() && TIERS[plan][frequency.toLowerCase()]} x{' '}
            {frequency.toLowerCase() === 'annual' ? 12 : 1}
          </span>
        )}
      </p>

      <span className="text-xs tracking-tight">
        Your credit card will be charged{' '}
        <span>
          {' '}
          $
          {isBrowser() &&
            (
              TIERS[plan][frequency.toLowerCase()] * (frequency.toLowerCase() === 'annual' ? 12 : 1)
            ).toFixed(2)}{' '}
        </span>{' '}
        <span>{frequency.toLowerCase() === 'annual' ? 'yearly' : 'monthly'}</span>
        <span>
          {buyNow
            ? ', until you cancel by contacting us at admin@evalubox.com or visiting your Manage Account. By clicking "Subscribe"'
            : ` starting ${trialEndDay && trialEndDay} ${
                trialEndDate && trialEndDate
              }, unless you cancel before then by visiting
        your Manage Account. By clicking "Start Trial"`}
        </span>
        , you acknowledge that you have read and agreed to our{' '}
        <a
          href="https://www.evalubox.com/terms"
          className="text-blue-600 hover:underline cursor-pointer"
          target="_blank"
        >
          Terms of Service
        </a>
        ,{' '}
        <a
          href="https://www.evalubox.com/legal-notices/cancellation-policy/"
          className="text-blue-600 hover:underline cursor-pointer"
          target="_blank"
        >
          Cancellation Policy
        </a>{' '}
        , and{' '}
        <a
          href="https://www.evalubox.com/privacy"
          className="text-blue-600 hover:underline cursor-pointer"
          target="_blank"
        >
          Privacy Policy
        </a>
      </span>
    </div>
  );
};
