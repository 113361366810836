import React from 'react';
import { Button, Headline } from '../../../components';
import CatalogScreenshot from '../../../../static/images/landing/screenshot-catalog.png';
import HeroForm from './HeroForm';
import { LOGOS } from '../CustomerLogoCloud';
import has from 'lodash/has';
import ImageCarousel from '../../ImageCarousel';
import TemplatePic from '../../../../static/images/logos/TemplatePicEx.png';
import NamePronounExampleVideo from '../../../../static/images/logos/WhatIsEvalubox.mp4';
import RequestAccessForm from './RequestAccessForm';
import { CheckIcon } from '@heroicons/react/solid';

const CheckedTierBullet = ({ feature, hasIcon = true }: any) => (
  <li className="flex space-x-3 mb-2">
    {hasIcon && (
      <span className="pt-1">
        <CheckIcon className="flex-shrink-0 h-5 w-5 text-primary-600" aria-hidden="true" />
      </span>
    )}
    <span className="prose prose-primary max-w-none text-xl" title={feature.sub && feature.sub}>
      {feature.disclaimer && (
        <small className=" block prose prose-primary max-w-none text-sm ">
          {feature.disclaimer}
        </small>
      )}
      {feature.title && feature.title}
      {feature.subtitle && (
        <small className=" block prose prose-primary max-w-none text-sm">{feature.subtitle}</small>
      )}
    </span>
  </li>
);

const CenteredHero = () => {
  const whiteLogos = [...LOGOS].filter(({ src }) => has(src, 'white')).slice(0, 4);
  const benefits = [
    {
      title:
        'Organize all of your reports in one dashboard and easily see which pieces you have completed',
      // title: "No more tracking down parents and teachers.",
      sub: 'Organize all of your reports in one dashboard and easily see which pieces you have completed',
      // subtitle: "Securely send and receive electronic questionnaires."
    },
    {
      title:
        'Gather parent, teacher, and health information with digital forms that automatically enter responses into your report',
      // title: "No more searching through old files for that perfect paragraph.",
      sub: 'Gather parent, teacher, and health information with digital forms that automatically enter responses into your report',
      // subtitle: "Select from a library of free pre-made templates and report paragraphs."
    },
    {
      title:
        'Add standardized testing results and impressions to automatically generated tables, charts, and paragraphs',
      // title: "No more digging through multiple documents to create a cohesive report.",
      sub: 'Add standardized testing results and impressions to automatically generated tables, charts, and paragraphs',
      // subtitle: "Intake information, parent and teacher forms, and assessment results feed directly into your report."
    },
    {
      title: 'Choose from a library of report templates (and) paragraphs',
      // title: "No more digging through multiple documents to create a cohesive report.",
      sub: 'Choose from a library of report templates (and) paragraphs',
      // subtitle: "Intake information, parent and teacher forms, and assessment results feed directly into your report."
    },
    {
      title: 'Generate S.M.A.R.T. goals and recommendations with the click of a button',
      // title: "No more digging through multiple documents to create a cohesive report.",
      sub: 'Generate S.M.A.R.T. goals and recommendations with the click of a button',
      // subtitle: "Intake information, parent and teacher forms, and assessment results feed directly into your report."
    },
  ];
  return (
    <>
      <div className="relative landing-hero-background overflow-x-hidden">
        <div className="relative pb-0">
          <main className=" mx-auto max-w-7xl px-4 sm:mt-20 sm:px-6 ">
            <div className="container flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-fit lg:py-2 lg:flex-row lg:items-center">
              <div className="w-full lg:w-1/2">
                <div className="lg:max-w-lg">
                  <Headline size="medium" className="text-inherit leading-snug">
                    Write Comprehensive Speech Language Reports in a Fraction of the Time!
                  </Headline>

                  {/* <h2 className="mt-5 text-base text-inherit sm:mt-8 sm:text-xl lg:text-lg xl:text-xl"> */}
                  {/* Speech-Language Reports Made Easy helps you:  */}
                  {/* Evalubox is an evaluation writing program for speech pathologists that
                    integrates referral information, test scores, diagnostic decisions, templates,
                    and goals into a report with a click of a button */}
                  {/* </h2> */}
                  <ul className="list-disc pt-4 mt-2">
                    {benefits.map((benefit, index) => (
                      <CheckedTierBullet feature={benefit} key={index} />
                    ))}
                  </ul>
                  <div className="my-5 md:mt-8">
                    {/* <div className="flex w-full md:justify-start justify-center items-end">
                    <RequestAccessForm from={"home-page"} showTC={true} />
                    </div> */}
                    <div className="mt-8 flex justify-left">
                      <div className="inline-flex">
                        <Button
                          link={true}
                          to="/pricing/?utm_source=evalubox-home&utm_medium=internal&utm_campaign=february-2024&utm_term=center-hero&utm_content=cta-get-started"
                          color="primary"
                          text="Get Started"
                          size="large"
                        />
                      </div>
                      {/* 
                      <div className="ml-3 inline-flex">
                        <Button link={true} to="/request-demo/" color="inset" text="Request Demo" />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center w-full h-96 lg:w-1/2">
                {/* <img className="object-cover w-full h-full mx-auto rounded-md lg:max-w-2xl" src="https://images.unsplash.com/photo-1543269664-7eef42226a21?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt="glasses photo" /> */}
                {/* <video preload='auto' className='object-cover w-full h-full mx-auto rounded-md lg:max-w-2xl' autoPlay loop >
                      <source src="https://app.searchie.io/file/P5qn4n30Dr/embed" type="video/mp4"/>
                      your browser is not supported! 
                    </video> */}
                <iframe
                  width="560"
                  height="315"
                  className="rounded-md"
                  src={`https://app.searchie.io/file/3w2V4Qz0NM/embed?autoplay=0`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                {/* <iframe
                  className="rounded-md w-full h-full"
                  src="https://app.searchie.io/file/3w2V4Qz0NM/embed?autoplay=0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe> */}
                {/* <ImageCarousel /> */}
              </div>
            </div>
          </main>
          {/* <div className="max-w-7xl mt-20 mx-auto">
            <ImageCarousel />
          </div> */}
        </div>
      </div>

      <script async src="https://player.vimeo.com/api/player.js" />
    </>
  );
};

export default CenteredHero;
