import React from 'react';
import { FaRegClock, FaCalculator } from 'react-icons/fa';
import { IconContext } from 'react-icons';

const AgeCalculatorIcon = ({ showIconText, ...rest }) => (
  <IconContext.Provider value={{ size: '70' }}>
    <div
      {...rest}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '140px',
        height: '140px',
      }}
    >
      <FaRegClock />
      {/* <FaCalculator style={{ position: 'absolute', top: '60px', left: '35px' }} /> */}
      {showIconText && (
        <div style={{ marginTop: '10px', fontSize: '12px', fontWeight: 'bold' }}>
          Age Calculator
        </div>
      )}
    </div>
  </IconContext.Provider>
);

export default AgeCalculatorIcon;
