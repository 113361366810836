import React, { useState } from 'react';
import { Button } from '../../components';
import { ExternalLinkIcon } from '@heroicons/react/outline';

import TeamsTier from './TeamsTier';
const PurchaseTeamsTier = () => (
  <TeamsTier
    ctaComponent={
      <Button
        text="Schedule a call"
        id="teams-plan-cta"
        link={true}
        color="primary"
        postfixIcon={<ExternalLinkIcon />}
        to="/request-demo/"
        fullWidth
        onClick={() =>
          window.analytics.track('Selected Schedule Call', {
            plan: 'teams-plan',
          })
        }
      />
    }
  />
);

export default PurchaseTeamsTier;
