import React from 'react';
import { Button, HelpText } from '..';

import TeamsTierPriceDisplay from './TeamsTierPriceDisplay';
import TeamsTierBenefits from './TeamsTierBenefits';
import TierContainer from './TierContainer';
import TierName from './TierName';
import TierDescription from './TierDescription';
import TIERS from './tiers';
import ExaminerTierBenefits from './ExaminerTierBenefits';
import ExaminerTierPriceDisplay from './ExaminerTierPriceDisplay';
import GrowthTierBenefits from './GrowthTierBenefits';
import ExaminerProTierBenefits from './ExaminerPremiumTierBenefits';
import ExaminerPremiumTierBenefits from './ExaminerPremiumTierBenefits';

// const ctaComponent = (isSlpImpact) => (
//   <Button
//     text={TIERS.examiner.ctaLabel}
//     link={true}
//     color="primary"
//     to={`${isSlpImpact ? TIERS.examiner.ctaLinkToSlpImpact : TIERS.examiner.ctaLinkTo}`}
//     fullWidth
//   />
// );
const ctaComponent = (currentlySetFrequency) => (
  <Button
    text={TIERS.premium.ctaLabel}
    link={true}
    color="primary"
    to={
      currentlySetFrequency === 'Monthly'
        ? `${TIERS.premium.ctaLinkTo}&frequency=Monthly`
        : `${TIERS.premium.ctaLinkTo}&frequency=Annual`
    }
    // to={TIERS.premium.ctaLinkTo}
    onClick={() =>
      window.analytics &&
      window.analytics.track('Selected Plan', {
        plan: TIERS.premium.planId,
        frequency: currentlySetFrequency,
      })
    }
    fullWidth
  />
);
const ExaminerPremiumTier = ({
  ctaHelpText = 'Power Evaluator',
  // ctaComponent = (
  //   <Button
  //     text={TIERS.premium.ctaLabel}
  //     link={true}
  //     color="primary"
  //     to={TIERS.premium.ctaLinkTo}
  //     fullWidth
  //   />
  // ),
  perSeatPrices,
  numberOfExaminers,
  setNumberOfExaminers,
  currentlySetFrequency,
  isSlpImpact,
}: any) => (
  <TierContainer highlight={true}>
    <div className="p-6">
      <TierName name={TIERS.premium.name} />
      <TierDescription description={TIERS.premium.description} />

      <ExaminerTierPriceDisplay
        perSeatPrices={perSeatPrices}
        numberOfExaminers={numberOfExaminers}
        setNumberOfExaminers={setNumberOfExaminers}
        currentlySetFrequency={currentlySetFrequency}
        isSlpImpact={isSlpImpact}
      />

      <div>
        <div className="mb-1">{ctaComponent(currentlySetFrequency)}</div>

        <div className="text-center">
          <HelpText message={ctaHelpText} />
        </div>
      </div>
    </div>

    <ExaminerPremiumTierBenefits />
  </TierContainer>
);

export default ExaminerPremiumTier;
