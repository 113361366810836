import React from 'react';
import { Link } from '../../components';
import { TwitterIcon, GitHubIcon, BackstageIcon, EvaluboxIcon } from '../../components/icons';
import camelCase from 'lodash/camelCase';

import soc2Seal from '../../../content/assets/logos/soc2/aicpa.jpg';

import theme from '../../theme';

const navigation = {
  resources: [
    {
      name: 'Free CEU: 7 Reasons Your Reports Take Too Long and How to Shorten the Process',
      href: 'https://bilinguistics.com/catalog/speech-pathology-continuing-education/video/7-reasons-your-reports-take-too-long/',
    },
    {
      name: 'Free CEU: 5 Ways SLPs are Increasing Their Income and Serving More Children',
      href: 'https://bilinguistics.com/catalog/speech-pathology-continuing-education/free-courses/5-ways-slps-are-increasing-their-income-and-serving-more-children/',
    },
    // { name: 'Free Tools', href: '/free' },
    { name: 'Speech Therapy Report Templates', href: '/resources/speech-therapy-report-templates' },
    { name: 'Chronological Age Calculator', href: '/resources/tools/chronological-age-calculator' },
    {
      name: 'Language Use and Exposure Calculator',
      href: '/resources/tools/language-use-exposure-calculator',
    },
  ],

  support: [
    // { name: 'Documentation', href: '/docs/' },
    // { name: 'Case Studies', href: '/case-studies/' },
    { name: 'Academy', href: 'https://academy.evalubox.com' },
    { name: 'FAQs', href: '/faqs/' },
    // { name: 'Free Tools', href: '/free' },
    // { name: 'Changelog', href: '/changelog/' },
    // { name: 'Use Cases', href: '/use-cases/' },
  ],

  company: [
    // { name: 'About Us', href: '/about/' },
    { name: 'Contact Us', href: 'https://help.evalubox.com/' },
    { name: 'Our story', href: '/about-us/' },
    { name: 'Why Evalubox', href: '/why-evalubox/' },
    { name: 'Blog', href: '/blog/' },
    // { name: 'Careers', href: 'https://careers.evalubox.com' },
  ],

  legalSecurity: [
    { name: 'Terms', href: '/legal-notices/terms-of-service/' },
    { name: 'Privacy', href: '/legal-notices/privacy-notice/' },
    { name: 'Cookies', href: '/legal-notices/cookies-policy/' },
    { name: 'Security', href: '/security/' },
  ],

  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/evalubox',
      icon: TwitterIcon,
    },
    {
      name: 'GitHub',
      href: 'https://github.com/evalubox',
      icon: GitHubIcon,
    },
    {
      name: 'Backstage',
      href: 'https://backstage.io',
      icon: BackstageIcon,
    },
  ],
};

const NavItem = ({ name, href }: any) => (
  <li key={name}>
    <Link
      to={href}
      id={`sitewide-footer-${name.toLowerCase()}`}
      className="text-base text-gray-500 hover:text-gray-900"
    >
      {name}
    </Link>
  </li>
);

const SocialItem = ({ item }: any) => (
  <Link key={item.name} to={item.href} className="text-gray-400 hover:text-gray-500">
    <span className="sr-only">{item.name}</span>
    <item.icon className="h-6 w-6" aria-hidden="true" />
  </Link>
);

const TitledLinkList = ({ title, linkListKey = camelCase(title) }: any) => (
  <>
    <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{title}</h3>
    <ul className="mt-4 space-y-4">
      {navigation[linkListKey].map((item) => (
        <NavItem {...item} key={item.name} />
      ))}
    </ul>
  </>
);

const CopyrightNotice = () => (
  <p className="text-base text-gray-400 xl:text-center">
    &copy; {new Date().getFullYear()} Speech Therapy Ai, Inc. All rights reserved.
  </p>
);

const SitewideFooter = ({ maxWidth = '7xl' }: any) => {
  return (
    <footer className="bg-white border-t-2 border-gray-100" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>

      <div className={`max-w-${maxWidth} mx-auto py-12 px-2 sm:px-6 lg:py-16 lg:px-8`}>
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-4 xl:col-span-1">
            <EvaluboxIcon fill={theme.COLORS_GRAY_500} />

            <p className="text-gray-500 text-base">
              {/* Evalubox is a software platform that helps K-12 special education evaluation teams reduce the time to evaluate students for special education services by over 70% by automating manual paper based solutions along with providing tools, resources, and community */}
              Evalubox helps special education professionals rapidly create accurate and
              professional evaluation reports. Our tools, resources, community, and digitized
              solutions reduce workload and increase compliance.
            </p>

            {/* <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <SocialItem item={item} key={item.name} />
              ))}
            </div> */}

            {/* <div className="flex items-center">
              <img
                src={soc2Seal}
                className="mr-2 h-8 w-8"
                alt="AICPA seal. Blue circle with the name AICPA inside."
              />
              <p className="text-base text-gray-500">Evalubox has gained SOC2 Type 2 compliance</p>
            </div> */}
          </div>

          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <TitledLinkList title="Resources" />
              </div>

              <div className="mt-12 md:mt-0">
                <TitledLinkList title="Support" />
              </div>
            </div>

            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <TitledLinkList title="Company" />
              </div>

              <div className="mt-12 md:mt-0">
                <TitledLinkList title="Legal & Security" />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-12 border-t border-gray-200 pt-8">
          <CopyrightNotice />
        </div>
      </div>
    </footer>
  );
};

export default SitewideFooter;
