import React from 'react';

import TierBulletsSection from './TierBulletsSection';

const ExaminerTierBenefits = ({ checkout = false }) => (
  <>
    {!checkout && (
      <TierBulletsSection
        hasIcon={false}
        bullets={[
          {
            title: 'Up to 30 Reports Annually',
          },
          // {
          //   title: '80 Evaluation Records*',
          // },
          // {
          //   title: '240 Digital Forms',
          // },
        ]}
      />
    )}
  </>
);

export default ExaminerTierBenefits;
