import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { SEO, SitewideHeader } from '../../components';
import BillingFrequencySwitcher from '../../components/pricing/BillingFrequencySwitcher';
import { PurchaseTeamsTier, SectionHeader } from '../../components/pricing';
import PricingFAQs from '../../components/landing/PricingFAQs';
import ExaminerPricingTier from '../../components/pricing/ExaminerPricingTier';
import ExaminerPremiumPricingTier from '../../components/pricing/ExaminerPremiumPricingTier';
import FeaturesLists from '../../components/Features/features-lists';
import { isBrowser } from '../../utils/isBrowser';
// Method two: using the feature flags component

const Pricing = ({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
  },
}: any) => {
  const [currentlySetFrequency, setFrequency] = useState('Annual');
  const [variant, setVariant] = useState('control');

  useEffect(() => {
    try {
      const variant =
        isBrowser && window.posthog && window?.posthog?.getFeatureFlag('pricing-slppro-only');
      setVariant(variant);
    } catch (error) {}
  }, []);

  return (
    <>
      <SitewideHeader borderBottom={false} />
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <SectionHeader headline="Plans and Pricing" />
            <BillingFrequencySwitcher
              setFrequency={setFrequency}
              currentlySetFrequency={currentlySetFrequency}
            />
            <span className="mx-auto mt-2 text-xl">
              {' '}
              Pay annually and <mark className="underline font-bold">save up to 30%</mark>
            </span>
          </div>
          <div
            className={`sm:grid-cols-3 mt-8 space-y-4 sm:mt-8 sm:space-y-0 sm:grid  sm:gap-6 lg:max-w-4xl lg:mx-auto`}
          >
            <ExaminerPricingTier currentlySetFrequency={currentlySetFrequency} />
            {/* {variant !== 'slp-pro' && ( */}
            <ExaminerPremiumPricingTier currentlySetFrequency={currentlySetFrequency} />
            {/* )} */}
            <PurchaseTeamsTier />
          </div>
          <div className="max-w-7xl mx-auto py-16 sm:py-24 sm:px-6 lg:px-8">
            {/* <div className="text-center mb-20">
             <Headline el="h2" size="small">
               Plan comparison
             </Headline>
           </div> */}
            <FeaturesLists />
            {/* <FeatureComparisonTable currentlySetFrequency={currentlySetFrequency} /> */}
          </div>
        </div>
      </div>
      <PricingFAQs />
    </>
  );
};

// type PricingTableProps = {
//   currentlySetFrequency: (frequency: string) => void
// }

// const DefaultPricingTable = ({currentlySetFrequency}: any) => {
//   return (
//     <>
//      <PostHogFeature flag='experiment-feature-flag-key' match={false}>
//         <div className="mt-8 space-y-4 sm:mt-8 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto">
//           <ExaminerPricingTier currentlySetFrequency={currentlySetFrequency} />
//           <ExaminerPremiumPricingTier currentlySetFrequency={currentlySetFrequency} />
//           <PurchaseTeamsTier />
//         </div>
//       </PostHogFeature>

//     </>
//   )
// }

// const SLPProPricingOnly = ({currentlySetFrequency}: any) => {
//   return (
//     <>
//       <PostHogFeature flag='experiment-feature-flag-key' match={'slp-pro'}>
//         <div className="mt-8 space-y-4 sm:mt-8 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl mx-auto lg:mx-auto">
//           <ExaminerPricingTier currentlySetFrequency={currentlySetFrequency} />
//           <PurchaseTeamsTier />
//         </div>
//       </PostHogFeature>
//     </>
//   )
// }

export default Pricing;

export const Head = () => (
  <>
    <SEO
      title={`Pricing`}
      description="Evaluation solution pricing from Evalubox. Simple, flexible pricing options to fit your evaluation needs. Get started."
    />
  </>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
