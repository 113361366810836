import React from 'react';
import Link from '../Link';
import ExaminerTierBenefits from '../pricing/ExaminerTierBenefits';

export const WhatsIncludedInPlan = () => {
  return (
    <div className="relative flex-1 text-inherit">
      <ExaminerTierBenefits checkout={true} />
      <div className="mt-6 text-inherit">
        <p>
          Working with a team or need more evaluations?{' '}
          <Link
            to="/request-demo/"
            className="text-blue-600 font-base hover:underline cursor-pointer"
          >
            Contact us{' '}
          </Link>{' '}
          about a Team account
        </p>
      </div>
    </div>
  );
};
