import React from 'react';
import { TextLink as Link, Button, Title } from '../../components';
import { ExternalLinkIcon, XIcon } from '@heroicons/react/outline';
import Modal, { modalStyles } from '../../components/Modal';

const twitterUrl = ({ social }) => `https://twitter.com/${social.twitter}`;

const FreeTrialCheckoutInner = ({ siteMetadata }) => (
  <div className="text-sm text-black">
    <Link
      to="https://app.evalubox.com/evaluations"
      className="text-sm text-blue-600 hover:underline"
      color="primary"
    >
      {' '}
      Already have an Evalubox account? Log in
    </Link>
    <p>
      You can also email us at{' '}
      <Link color="primary" to="mailto:admin@evalubox.com?subject=Help with new account">
        admin@evalubox.com
      </Link>{' '}
      to get help setting up your account.
    </p>
  </div>
);

const ConfirmDemoInner = ({ siteMetadata }) => (
  <p>
    Learn more about Evalubox via{' '}
    <Link to="https://academy.evalubox.com/" color="primary">
      our academy
    </Link>
    .
  </p>
);

const NewsletterAndTwitterInner = ({ siteMetadata }) => (
  <p>
    Learn more about Evalubox via{' '}
    <Link to="/backstage-weekly/" color="primary">
      our newsletter
    </Link>{' '}
    or follow{' '}
    <Link to={twitterUrl(siteMetadata)} color="primary">
      @{siteMetadata.social.twitter}
    </Link>
    .
  </p>
);

const TwitterInner = ({ siteMetadata }: any) => (
  <p>
    Follow{' '}
    <Link to={twitterUrl(siteMetadata)} color="primary">
      @{siteMetadata.social.twitter}
    </Link>
    .
  </p>
);

const GetDemoAndSignUpInner = ({ referredEmail }: any) => {
  const codedEmail = encodeURIComponent(referredEmail);

  return (
    <div className="flex justify-between">
      <GetDemoSurveyInner referredEmail={codedEmail} />
      <SignUpSurveyInner referredEmail={codedEmail} />
    </div>
  );
};

const GetDemoSurveyInner = ({ referredEmail }: any) => {
  const codedEmail = encodeURIComponent(referredEmail);

  return (
    <Button
      link={true}
      to={`/request-demo/?email=${codedEmail}`}
      icon={<ExternalLinkIcon />}
      text="Request a demo"
      color="primary"
    />
  );
};

const SignUpSurveyInner = ({ referredEmail }: any) => {
  const codedEmail = encodeURIComponent(referredEmail);

  return (
    <Button
      link={true}
      to={`/pricing/?email=${codedEmail}`}
      icon={<ExternalLinkIcon />}
      text="Sign Up"
      color="primary"
    />
  );
};

const GetTrialInner = ({ referredEmail }: any) => {
  const codedEmail = encodeURIComponent(referredEmail);

  return (
    <Button
      link={true}
      to={`/free-trial/?email=${codedEmail}`}
      prefixIcon={<ExternalLinkIcon />}
      text="Request a free trial"
      color="primary"
    />
  );
};

const FormSubmissionModal = ({
  modalOpen,
  handleCloseModal,
  titleText = 'Thank you!',
  bodyText = (
    <p>
      We&apos;ll be in touch to learn more about your stack and the problems you&apos;re trying to
      solve.
    </p>
  ),
  siteMetadata = {
    social: {
      twitter: 'st',
    },
  },
  followOn = 'NEWSLETTER_AND_TWITTER',
  email,
  titleEmoji = (
    <span aria-label="Party Streamers" role="img">
      🎉
    </span>
  ),
}: any) => {
  let followOnContent;
  if (followOn === 'NEWSLETTER_AND_TWITTER') {
    followOnContent = <NewsletterAndTwitterInner siteMetadata={siteMetadata} />;
  } else if (followOn === 'TWITTER') {
    // Doesn't make sense to offer to let people sign up to the newsletter immediately after
    // they have just signed up to the newsletter.
    followOnContent = <TwitterInner siteMetadata={siteMetadata} />;
  } else if (followOn === 'GET_DEMO_SURVEY') {
    followOnContent = <GetDemoSurveyInner referredEmail={email} />;
  } else if (followOn === 'CONFIRM_DEMO') {
    followOnContent = <ConfirmDemoInner siteMetadata={siteMetadata} />;
  } else if (followOn === 'REQUEST_DEMO') {
    followOnContent = <GetDemoSurveyInner referredEmail={email} />;
  } else if (followOn === 'REQUEST_DEMO_AND_SIGNUP') {
    followOnContent = <GetDemoAndSignUpInner referredEmail={email} />;
  } else if (followOn === 'GET_TRIAL') {
    followOnContent = <GetTrialInner referredEmail={email} />;
  } else if (followOn === 'FREE_TRIAL_CHECKOUT') {
    followOnContent = <FreeTrialCheckoutInner siteMetadata={siteMetadata} />;
  }

  return (
    <Modal
      isOpen={modalOpen}
      style={modalStyles({ maxWidth: 660 })}
      contentLabel="Modal"
      onRequestClose={handleCloseModal}
      shouldCloseOnOverlayClick={false}
    >
      <div className="p-4">
        <div className="mb-4 flex items-center justify-between">
          <Title>
            {titleText} {titleEmoji}
          </Title>

          <button onClick={handleCloseModal}>
            <XIcon className="h-8 w-8" />
          </button>
        </div>

        <div className="prose prose-primary max-w-none mb-4 pb-8 border-b-2 border-gray-100">
          {bodyText}
        </div>

        <div className="prose prose-primary max-w-none mb-4">{followOnContent}</div>
      </div>
    </Modal>
  );
};

export default FormSubmissionModal;
