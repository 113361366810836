import React, { useEffect } from 'react';
import { useState } from 'react';
import { graphql, navigate } from 'gatsby';
import { WhatsIncludedInPlan } from '../components/Registration/PlanDetails';
import BasicCheckoutForm from '../components/Registration/BasicCheckoutForm';
import Logo from '../components/Logo';
import { SEO } from '../components';
import { WhatsIncludedInBasicPlan } from '../components/Registration/BasicPlanDetails';

const isBrowser = () => typeof window !== 'undefined';
const FREE_TRIAL_DAYS = 30;
export const Registration = ({ cbInstance }: any) => {
  const [heading, setHeading] = useState('SLP Pro');
  const params = new URLSearchParams(isBrowser() && location.search);
  const planFromUrl = decodeURIComponent(params.get('plan') || '');
  const frequencyFromUrl = decodeURIComponent(params.get('frequency') || 'annual');

  const planName = {
    pro: `SLP Pro ${frequencyFromUrl} Plan`,
    premium: `Power Evaluator ${frequencyFromUrl} Plan`,
  };
  useEffect(() => {
    if (
      ['pro', 'premium'].includes(planFromUrl) &&
      ['annual', 'monthly'].includes(frequencyFromUrl.toLowerCase())
    ) {
      setHeading(planName[planFromUrl]);
    } else {
      navigate('/pricing');
    }
  }, [planFromUrl]);
  const [buyNow, setBuyNow] = useState(true);
  return (
    <section className="w-full">
      <h1 className="sr-only">Checkout</h1>
      <div className="relative mx-auto ">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="bg-gray-50 py-12 md:py-20 w-full">
            <div className="flex justify-start lg:w-0 lg:flex-1 top-0 left-0  px-6 py-4 absolute">
              <Logo to={'/pricing'} />
            </div>
            <div className="md:mt-10 md:mt-0 lg:mt-0 max-w-2xl mx-auto px-4 md:px-10">
              <h1 className="text-6xl font-semibold text-stai-1">{heading}</h1>
              {/* <h2 className="mx-auto mt-2 text-xl font-medium">Billing Frequency: {frequencyFromUrl}</h2> */}
              <WhatsIncludedInBasicPlan />
            </div>
            <div className="mt-10 md:mt-0 lg:mt-0 mx-auto max-w-xl px-4 lg:px-8">
              <WhatsIncludedInPlan />
            </div>
          </div>
          <div className="bg-white py-12 md:py-20">
            <div className="mx-auto max-w-2xl px-4 lg:px-8">
              <BasicCheckoutForm
                cbInstance={cbInstance}
                buyNow={buyNow}
                trialDays={FREE_TRIAL_DAYS}
                plan={planFromUrl}
                frequency={frequencyFromUrl}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <FreeTrialFAQs cycleFromUrl={frequencyFromUrl.toLowerCase()} buyNow={buyNow} /> */}
    </section>
  );
};

const GetStarted = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const [cbInstance, setCbInstance] = useState();
  React.useEffect(() => {
    setCbInstance(window.Chargebee.getInstance());
  }, []);
  return (
    <>
      <SEO
        title={`${SEO_TITLE} | ${siteTitle}`}
        description="We handle evaluation reports for you and help you manage your evaluations, with just a few clicks and let you get back to your clients. Try for free."
      />
      <Registration cbInstance={cbInstance} />
    </>
  );
};

export default GetStarted;

const SEO_TITLE = 'Get Started with Evalubox';
export const Head = () => (
  <>
    <SEO title={SEO_TITLE} />
  </>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
