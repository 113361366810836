import React, { useState } from 'react';
import { Button } from '..';
import { PER_EXAMINER_PRO_PRICES } from './prices';
import { ExternalLinkIcon } from '@heroicons/react/outline';

import TeamsTier from './TeamsTier';

import { chargebeeSubdomain, chargebeeTestSubdomain } from '../../environment';
import ExaminerTier from './ExaminerTier';

const PurchaseExaminerProTier = ({ currentlySetFrequency }: any) => {
  const [numberOfEngineers, setNumberOfEngineers] = useState<any>(PER_EXAMINER_PRO_PRICES[0].id);

  const chargebeePlanName = `individual-examiner-professional-${currentlySetFrequency.toLowerCase()}`;
  const chargebeeUrl = new URL(
    `https://${chargebeeSubdomain()}.chargebee.com/hosted_pages/plans/${
      currentlySetFrequency === 'Monthly'
        ? 'individual-examiner-professional-monthly'
        : 'individual-examiner-professional-annual'
    }`
  );
  chargebeeUrl.searchParams.append('subscription_items[item_price_id][0]', chargebeePlanName);
  chargebeeUrl.searchParams.append('subscription_items[quantity][0]', numberOfEngineers);

  return (
    <ExaminerTier
      numberOfEngineers={numberOfEngineers}
      setNumberOfEngineers={setNumberOfEngineers}
      currentlySetFrequency={currentlySetFrequency}
      perSeatPrices={PER_EXAMINER_PRO_PRICES}
      ctaHelpText="Powered by Chargebee and Stripe"
      ctaComponent={
        <Button
          text="Get Started"
          id="examiner-starter-plan-cta"
          postfixIcon={<ExternalLinkIcon />}
          link={true}
          to={chargebeeUrl.toString()}
          color="primary"
          forceOpenInSameTab={true}
          fullWidth
        />
      }
    />
  );
};

export default PurchaseExaminerProTier;
