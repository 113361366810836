import * as React from 'react';
import Headline from '../Headline';
import {
  FaTasks,
  FaLock,
  FaRegEdit,
  FaRegCheckSquare,
  FaRegFileAlt,
  FaRegChartBar,
} from 'react-icons/fa'; // Track Status
import { LiaClipboardListSolid } from 'react-icons/lia';
import { FiLink } from 'react-icons/fi';
import { PiHeadsetBold } from 'react-icons/pi';
const features = [
  {
    title: 'Organized',
    description: 'See all of your reports in one central dashboard',
    icon: LiaClipboardListSolid,
  },
  {
    title: 'Track Status',
    description: 'Know what you need to complete for each report and which have been finished',
    icon: FaTasks,
  },
  {
    title: 'Digital Intake',
    description: 'Generate secure links to forms for parents, teachers and nurses',
    icon: FiLink,
  },
  {
    title: 'Integration',
    description: 'Fillable charts for all major standardized assessments',
    icon: FaRegChartBar,
  },
  {
    title: 'Templating',
    description: 'Modifiable templates for clinics, schools, or joint reports',
    icon: FaRegFileAlt,
  },
  {
    title: 'Recommendations',
    description: 'Select from pre-written goals, assurances, and recommendations',
    icon: FaRegCheckSquare,
  },
  {
    title: 'Flexible',
    description: 'Easily edit, modify, and print your report up copy individual IEP segments',
    icon: FaRegEdit,
  },
  {
    title: 'Secure',
    description: 'HIPPA and FERPA compliant',
    icon: FaLock,
  },
  {
    title: 'Supported',
    description: 'Dedicated chat, email, and phone support',
    icon: PiHeadsetBold,
  },
];
const FeatureWithIcon = ({ icon }) => {
  const IconComponent = icon;

  return (
    <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-white rounded-full bg-primary">
      {icon ? (
        <IconComponent className="w-6 h-6" />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5.25 14.25h13.5m-13.5 0a3 3 0 01-3-3m3 3a3 3 0 100 6h13.5a3 3 0 100-6m-16.5-3a3 3 0 013-3h13.5a3 3 0 013 3m-19.5 0a4.5 4.5 0 01.9-2.7L5.737 5.1a3.375 3.375 0 012.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 01.9 2.7m0 0a3 3 0 01-3 3m0 3h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008zm-3 6h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008z"
          />
        </svg>
      )}
    </dt>
  );
};

export default function FeaturesLists(props: any) {
  return (
    <section>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-4 lg:pb-12">
        <div className="flex flex-wrap items-center mx-auto max-w-7xl">
          <div className="flex flex-col w-full mb-12 text-center">
            {/* <h1 className="max-w-5xl text-xl font-bold leading-none tracking-tighter text-stai-1 md:text-4xl lg:text-4xl lg:max-w-7xl">
              Features
            </h1> */}
            <Headline el="h2" size="small">
              Features
            </Headline>
          </div>
          <div className="flex flex-col items-start mb-16 text-left lg:flex-grow lg:w-full lg:pl-6 xl:pl-24 md:mb-0 xl:mt-0">
            <dl className="grid grid-cols-1 gap-8 md:grid-cols-3">
              {features.map((feature, index) => (
                <div key={index}>
                  {/* <dt className="inline-flex items-center justify-center flex-shrink-0 w-12 h-12 mb-5 text-indigo-600 rounded-full bg-blue-50">
                      {feature.icon ? feature.icon : <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5.25 14.25h13.5m-13.5 0a3 3 0 01-3-3m3 3a3 3 0 100 6h13.5a3 3 0 100-6m-16.5-3a3 3 0 013-3h13.5a3 3 0 013 3m-19.5 0a4.5 4.5 0 01.9-2.7L5.737 5.1a3.375 3.375 0 012.7-1.35h7.126c1.062 0 2.062.5 2.7 1.35l2.587 3.45a4.5 4.5 0 01.9 2.7m0 0a3 3 0 01-3 3m0 3h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008zm-3 6h.008v.008h-.008v-.008zm0-6h.008v.008h-.008v-.008z"
                        />
                      </svg>}
                    </dt> */}
                  <FeatureWithIcon icon={feature.icon} />
                  <dd className="flex-grow">
                    <h3 className="mb-3 text-2xl font-bold tracking-tighter text-stai-1">
                      {feature.title}
                    </h3>
                    <p className="text-base leading-relaxed text-gray-700">{feature.description}</p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
}
