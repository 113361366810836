import React from 'react';
import { graphql } from 'gatsby';

import ellenBlackSuit from '../../content/team/ellen-kester/black-suit.webp';
import { Headline, Lead, SEO, SitewideFooter, SitewideHeader } from '../components';
import mailMerge from '../../static/images/landing/mail-merge.jpg';

import {
  CircleThreeColTeam as Team,
  SimpleCenteredHeading,
  ExplainingTheVision,
  Values,
  FooterCTA,
} from '../components/landing';
import OurStory from '../components/OurStory';
import MLAReferences from '../components/landing/MLAReferences';

const About = ({ data: { team } }: any) => (
  <>
    <SitewideHeader />

    <div className="bg-white">
      <SimpleCenteredHeading
        headline="Welcome to Evalubox."
        lead="We are a team of speech-language pathologists, parents of children with special needs, evaluators and engineers. We have seen first hand what it is like to not have the services you need as a person with a disability. We also know first hand how difficult it can be to deliver evaluations and services."
      />
    </div>
    <div className="flex items-center justify-center w-full ">
      <iframe
        width="560"
        height="315"
        className="rounded-md"
        src={`https://app.searchie.io/file/47NJv5VzNo/embed`}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>

    <Team
      headline="Our Team"
      lead="We are a team of speech language pathologists, parents of children with special needs, evaluators and engineers. We are located in Atlanta, Austin, and Sydney."
      people={team.edges.map(({ node }) => node.frontmatter)}
    />
    <Values />

    <FooterCTA />
    <SitewideFooter />
  </>
);

export default About;

export const Head = () => (
  <>
    <SEO
      title={`About us`}
      description="We are a team of speech-language pathologists, parents of children with special needs, evaluators and engineers."
    />
  </>
);

export const pageQuery = graphql`
  {
    team: allMarkdownRemark(
      sort: { frontmatter: { name: ASC } }
      filter: { fileAbsolutePath: { regex: "/.+/content/team/.+/" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            name
            role
            bio
            location
            linkedinUrl
            twitterUrl
            githubUrl
            headshot {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
