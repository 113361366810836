import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { SEO } from '../../components';
import BillingFrequencySwitcher from '../../components/pricing/BillingFrequencySwitcher';
import { PurchaseTeamsTier, SectionHeader } from '../../components/pricing';
import { FAQs } from '../../components/landing';
import PurchaseExaminerPremiumTier from '../../components/pricing/PurchaseExaminerPremiumTier';
import PurchaseExaminerProTier from '../../components/pricing/PurchaseExaminerProTier';
const Purchase = ({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
  },
}: any) => {
  const [currentlySetFrequency, setFrequency] = useState('Annual');
  return (
    <>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <SectionHeader headline="Purchase Evalubox" />
            <BillingFrequencySwitcher
              setFrequency={setFrequency}
              currentlySetFrequency={currentlySetFrequency}
            />
          </div>
          <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto">
            <PurchaseExaminerProTier currentlySetFrequency={currentlySetFrequency} />
            <PurchaseExaminerPremiumTier currentlySetFrequency={currentlySetFrequency} />
            <PurchaseTeamsTier />
          </div>
        </div>
      </div>
      <FAQs />
    </>
  );
};

export default Purchase;

export const Head = () => (
  <>
    <SEO
      title={`Purchase`}
      description="Evaluation solution pricing from Evalubox. Simple, flexible pricing options to fit your evaluation needs. Buy now."
    />
  </>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
