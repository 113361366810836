import React from 'react';
import { QuoteIcon } from '../../icons';
import { WhatsIncludedInPlan } from '../../Registration/PlanDetails';

import Avatar from './Avatar';

const FreeTrialSidebarTestimonial = ({
  companyLogoPng,
  companyLogo,
  companyLogoAlt,
  quote,
  avatar,
  quoteeName,
  quoteeRole,
}: any) => (
  <div className="py-1 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 lg:pr-16">
    {companyLogo && (
      <div className="md:flex-shrink-0">
        <picture>
          <source srcSet={companyLogo} type="image/webp" />
          <source srcSet={companyLogoPng} type="image/png" />

          <img src={companyLogoPng} alt={companyLogoAlt} height="33" width="160" />
        </picture>
      </div>
    )}

    <blockquote className="mt-0 md:mt-6 md:flex-grow md:flex md:flex-col">
      <div className="relative text-lg font-medium text-inherit md:flex-grow">
        <QuoteIcon />

        <p className="relative pl-6">{quote}</p>
      </div>

      <footer className="mt-8">
        <div className="flex items-start">
          <Avatar avatar={avatar} />

          <div className="ml-4">
            <div className="text-base font-medium text-inherit">{quoteeName}</div>
            <div className="text-base font-medium text-primary-400">{quoteeRole}</div>
          </div>
        </div>
      </footer>
    </blockquote>
  </div>
);

export default FreeTrialSidebarTestimonial;
