import React from 'react';
import { Button } from '../../components';

const MobileFreeTrialButton = () => (
  <Button
    link={true}
    to="/pricing/"
    color="primary"
    text="Get Started"
    size="large"
    fullWidth={true}
  />
);

export default MobileFreeTrialButton;
