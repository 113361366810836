import React, { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import {
  CodeIcon,
  BookOpenIcon,
  ShieldCheckIcon,
  AcademicCapIcon,
  SparklesIcon,
  DesktopComputerIcon,
  NewspaperIcon,
  BriefcaseIcon,
  PlayIcon,
  BookmarkIcon,
} from '@heroicons/react/outline';

import MobileFreeTrialButton from './MobileFreeTrialButton';
import MobileDropdownNavItem from './MobileDropdownNavItem';
import DrawerMenuHeader from './DrawerMenuHeader';

const subItems = [
  {
    name: 'Evalubox Academy',
    description: 'Become an expert quickly with short, searchable, video tutorials.',
    to: 'https://academy.evalubox.com',
    icon: PlayIcon,
  },
  {
    name: 'FAQ',
    description: 'Answers to frequently asked questions.',
    to: '/faqs/',
    icon: AcademicCapIcon,
  },
  {
    name: 'Plans',
    to: '/pricing/',
    icon: BookmarkIcon,
  },
  // {
  //   name: 'Backstage Weekly',
  //   to: '/backstage-weekly/',
  //   icon: NewspaperIcon,
  // },
  // {
  //   name: 'Academy',
  //   to: 'https://academy.evalubox.com',
  //   icon: BookOpenIcon,
  // },
  // {
  //   name: 'Case Studies',
  //   to: '/case-studies/',
  //   icon: ShieldCheckIcon
  // },
  // {
  //   name: 'Documentation',
  //   to: '/docs/',
  //   icon: AcademicCapIcon,
  // },
  {
    name: 'Request Demo',
    to: '/request-demo/',
    icon: BriefcaseIcon,
  },
];

const DrawerMenu = () => (
  <Transition
    as={Fragment}
    enter="duration-200 ease-out"
    enterFrom="opacity-0 scale-95"
    enterTo="opacity-100 scale-100"
    leave="duration-100 ease-in"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-95"
  >
    <Popover.Panel
      focus
      className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
    >
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
        <div className="pt-5 pb-6 px-5">
          <DrawerMenuHeader />

          <div className="mt-6">
            <nav className="grid gap-y-8">
              {subItems.map((item) => (
                <MobileDropdownNavItem item={item} key={item.name} />
              ))}
            </nav>
          </div>
        </div>

        <div className="py-6 px-5 space-y-6">
          <div>
            <MobileFreeTrialButton />
          </div>
        </div>
      </div>
    </Popover.Panel>
  </Transition>
);

export default DrawerMenu;
