import React from 'react';
import { graphql } from 'gatsby';

import { SEO, Headline, Lead, TextLink as Link } from '../../components';
const PurchaseSuccessPage = ({
  data: {
    site: {
      siteMetadata: { title },
    },
  },
}: any) => (
  <>
    <div className="bg-white">
      <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-xl mx-auto py-16 sm:py-24">
          <div className="text-center">
            <div className="flex flex-col items-center p-4 space-y-2 bg-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="text-green-600 w-28 h-28"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>

            <div className="mt-2">
              <Headline size="medium" el="h2">
                Check your email for your login details
              </Headline>
            </div>
          </div>
          <div className="mt-4 text-left bg-gray-100 p-10 my-6 w-full mx-auto">
            <div className="mb-2">
              <Lead>Please check your email for your login details.</Lead>
              <Lead>
                From: <mark>no-reply@evalubox.com</mark>{' '}
              </Lead>
              <Lead>
                Subject: <mark>Finish setting up your Evalubox account</mark>{' '}
              </Lead>
            </div>
          </div>

          <div className="max-w-xl px-5 text-center">
            <a
              href="https://app.evalubox.com/evaluations"
              className="mt-3 inline-block w-fit rounded bg-primary px-5 py-3 font-medium text-white shadow-md hover:text-white hover:bg-primary-700"
            >
              Open Evalubox →
            </a>
          </div>
          <div className="mt-4 text-center">
            <div className="mb-2">
              Please{' '}
              <Link
                className="text-blue-700"
                to="mailto:admin@evalubox.com?subject=Did not receive email after creating account"
              >
                contact us here
              </Link>{' '}
              if you haven't received an email.
              <p>admin@evalubox.com</p>
            </div>
          </div>
        </div>
      </main>
    </div>
  </>
);

export default PurchaseSuccessPage;

export const Head = () => (
  // Exclude from robots/seo
  <>
    <SEO
      title={`Welcome to Evalubox`}
      description="Success page which is shown to people who become Evalubox customers"
    />
  </>
);

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
