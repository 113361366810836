import React from 'react';
import { Headline, TextLink as Link } from '..';

const FAQ_CONTENT = [
  {
    question: `Do you have a free trial?`,
    answer: () =>
      `Not at this time. Instead, schedule a demo to the amazing way Evalubox collects report information and helps you write reports.`,
  },
  {
    question: `What are your payment options?`,
    answer: () =>
      `We offer both monthly and discounted annual plans. Easily pay online through our secure payment processing with any major credit card. For school districts, we also accept Purchase Orders (POs).`,
  },
  {
    question: `Can I switch billing frequency?`,
    answer: () => <p>Yes. Simply contact us via email and we will adjust your plan accordingly.</p>,
  },
  {
    question: `What is your cancellation policy?`,
    answer: () => <p>Cancel anytime for a full refund within the first 14 days.</p>,
  },
  {
    question: `What if I don’t have any evaluations right now?`,
    answer: () => (
      <p>
        Two of the most popular features of Evalubox are needed before the evaluation begins:
        organizing all of your upcoming evaluations in one place, and taking advantage of the
        electronic forms to gather information from parents, teachers, and nurses. Plus, our Academy
        will get you up-and-running with the how-tos of Evalubox so you are prepared when the
        evaluations come rolling in.
      </p>
    ),
  },
  {
    question: `Does my team qualify for any discounts?`,
    answer: () =>
      `Yes! There is reduced pricing per professional. Pricing varies based on team size so schedule a call and plan a  tour and onboarding session. `,
  },
];

const Item = ({ question, answer }) => (
  <div>
    <dt className="font-semibold text-gray-900">{question}</dt>
    <dd className="mt-3 text-gray-500">{answer()}</dd>
  </div>
);

const PricingFAQs = ({
  faqs = FAQ_CONTENT,
  heading = 'Frequently asked questions',
  freeTrial = false,
}: any) => (
  <div className="bg-white">
    <div className="max-w-7xl mx-auto pb-16 px-4  sm:px-6 lg:px-8">
      <div className="max-w-2xl lg:mx-auto lg:text-center">
        <Headline el="h2" size="medium">
          {heading}
        </Headline>
      </div>

      <div className="mt-20 px-8">
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
          {faqs.map((faq) => (
            <Item {...faq} key={faq.question} />
          ))}
        </dl>
      </div>
    </div>
  </div>
);

export default PricingFAQs;
