import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV,
  Provider,
} from '@chargebee/chargebee-js-react-wrapper';
import React from 'react';
import { useState } from 'react';
// import Loader from "../Loader/loader";
export const ChargebeeCard = ({ cbInstance, cardRef, complete, setComplete, onReady }) => {
  var options = {
    icon: true,
    classes: {
      focus: 'focus',
      invalid: 'invalid',
      empty: 'empty',
      complete: 'complete',
    },

    style: {
      // Styles for default field state
      base: {
        color: '#333',
        fontWeight: '500',
        fontFamily: 'Roboto, Segoe UI, Helvetica Neue, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',

        ':focus': {
          color: '#424770',
        },

        '::placeholder': {
          color: '#acb1bb',
          fontSize: '12px',
          fontWeight: '300',
        },

        ':focus::placeholder': {
          color: '#7b808c',
        },
      },

      // Styles for invalid field state
      invalid: {
        color: '#e41029',

        ':focus': {
          color: '#e44d5f',
        },
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    },

    // locale
    locale: 'en',

    // Custom placeholders
    placeholder: {
      number: 'Card number',
      expiry: 'MM / YY',
      cvv: 'CVV',
    },

    // Custom fonts
    fonts: ['https://fonts.googleapis.com/css?family=Roboto:300,500,600'],
  };

  const [errors, setErrors] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<any>('');
  const onChange = (event) => {
    setComplete(event.complete);
    let errorMessage = '';

    if (event.error) {
      // If error is present, setState and display the error
      errors[event.field] = event.error;
      errorMessage = event.error.message;
      setComplete(false);
    } else {
      errors[event.field] = null;
      // If there's no error, check for existing error
      const _errors = Object.values(errors).filter((val) => val);

      // The errorObject holds a message and code
      // Custom error messages can be displayed based on the error code
      const errorObj: any = _errors.pop();

      // Display existing message
      if (errorObj) errorMessage = errorObj.message;
      else errorMessage = '';
    }
    setErrors(errors);
    setErrorMessage(errorMessage);
  };
  return (
    <>
      <Provider cbInstance={cbInstance}>
        <CardComponent
          ref={cardRef}
          className="field relative w-full rounded-lg shadow-sm border p-4 text-sm placeholder-gray-400 focus:z-10"
          onChange={onChange}
          onReady={onReady}
          styles={options.style}
          classes={options.classes}
          locale={options.locale}
          placeholder={options.placeholder}
          fonts={options.fonts}
        />
      </Provider>
      {errorMessage && (
        <div className="error text-red-600 font-base text-sm px-2" role="alert">
          {errorMessage}
        </div>
      )}
    </>
  );
};
