import React, { useState } from 'react';

import trackGoogleAnalyticsEvent from '../../googleAnalytics';
import trackPlausibleEvent from '../../plausible';
import EmailCaptureForm from './EmailCaptureForm';
import { FORM_NAMES, HONEYPOT_FIELD_NAME } from '../../contactFormConstants';
import { currentlyExecutingGitBranch, recaptchaEnabled } from '../../environment';

export const encode = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return formData;
};

const urlEncode = function (data) {
  var str = [];
  for (var p in data) {
    if (data.hasOwnProperty(p) && !(data[p] == undefined || data[p] == null)) {
      str.push(encodeURIComponent(p) + '=' + (data[p] ? encodeURIComponent(data[p]) : ''));
    }
  }
  return str.join('&');
};

export const submitEmailToNetlifyForms = async ({
  email,
  netlifyFormName,
  honeypotText,
  recaptchaResponse,
  submitButtonLabel = 'NOT_SUPPLIED',
  selectedOption,
  results,
}: any) => {
  const branch = currentlyExecutingGitBranch();

  if (!email || email === '') {
    return Promise.reject();
  }

  let resp;

  // window.rudderanalytics.identify('',{ email: email})

  const anonymousId = await window.rudderanalytics.getAnonymousId();
  // console.log("annonymousId", anonymousId)
  try {
    const bodyParams: any = {
      email,
      content: 'This is from our marketing site.',
      'form-name': netlifyFormName,
      'submit-button-label': submitButtonLabel,
      'deployed-branch': branch,
      [HONEYPOT_FIELD_NAME]: honeypotText,
      selectedOption,
      results: JSON.stringify(results),
      rudderAnonymousId: anonymousId,
    };

    if (recaptchaEnabled()) {
      bodyParams['g-recaptcha-response'] = recaptchaResponse;
    }

    resp = await fetch('https://resources-api.app.evalubox.com/pdf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyParams),
    });
    // console.log("resp", resp)
  } catch (error) {
    console.error('Submission failed', error, resp);
    return Promise.reject();
  }

  // trackGoogleAnalyticsEvent({
  //   category: 'form',
  //   action: 'submit',
  //   label: netlifyFormName,
  // });

  // trackPlausibleEvent(netlifyFormName);

  return resp;
};

const LanguageExposureReportEmailFormCallToAction = ({
  placeholderText = 'Work email',
  buttonText = 'Click here',
  subFormMessage = 'We will never sell or share your email address.',
  netlifyFormName = FORM_NAMES.subscribeToNewsletter,
  setModalOpen,
  autoFocus = false,
  email,
  setEmail,
  results,
  selectedOption,
  ...rest
}: any) => {
  const [submitting, setSubmitting] = useState(false);
  const [honeypotText, setHoneypotText] = useState('');
  const [recaptchaResponse, setRecaptchaResponse] = useState('');
  const [recaptchaExpired, setRecaptchaExpired] = useState(false);
  const [subForm, setSubForm] = useState<any>({
    message: subFormMessage,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const resp = await submitEmailToNetlifyForms({
      email,
      netlifyFormName,
      honeypotText,
      recaptchaResponse,
      submitButtonLabel: buttonText,
      selectedOption,
      results,
    });

    if (resp.ok) {
      setModalOpen(true);
      // DO NOT reset the email input here. It is already happening higher in the state chain.
    } else {
      setSubForm({
        state: 'error',
        message: 'Something went wrong. Please try that again.',
      });
    }

    setSubmitting(false);
  };

  const propsToPass = {
    onSubmit: onSubmit,
    submitting: submitting,
    emailInputId: `${netlifyFormName}-email-input`,
    buttonId: `${netlifyFormName}-email-button`,
    subForm: subForm,
    setEmail: setEmail,
    placeholderText: placeholderText,
    email: email,
    buttonText: buttonText,
    autoFocus: autoFocus,
    netlifyFormName: netlifyFormName,
    honeypotValue: honeypotText,
    onHoneypotChange: setHoneypotText,
    recaptchaResponse,
    setRecaptchaResponse,
    recaptchaExpired,
    setRecaptchaExpired,
    ...rest,
  };

  return <EmailCaptureForm {...propsToPass} />;
};

export default LanguageExposureReportEmailFormCallToAction;
