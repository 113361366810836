import React from 'react';
import { CheckIcon } from '@heroicons/react/solid';

import laurenAvatar from '../../../content/assets/home/testimonial/lauren-bilinguistics/avatar.webp';
import laurenAvatarJpeg from '../../../content/assets/home/testimonial/lauren-bilinguistics/avatar.jpg';
import FreeTrialSidebarTestimonial from '../landing/Testimonials/FreeTrialSidebarTestimonial';

export const WhatsIncludedInBasicPlan = () => {
  return (
    <div className="relative flex-1 text-inherit">
      {/* <ExaminerTierBenefits /> */}
      <div className="text-black hidden md:block">
        <FreeTrialSidebarTestimonial
          quote="Evalubox has been an excellent guide for me as an SLP that is new to school based evaluations. 
              Coming from a clinic setting, I was a bit nervous about making sure all necessary information was included in an organized way in my reports. This tool has been very helpful in completing thorough, organized evaluations in a reasonable amount of time. "
          avatar={{
            webp: laurenAvatar,
            png: laurenAvatarJpeg,
            alt: 'Headshot of a womain facing the camera.',
          }}
          quoteeName="Lauren Hudgins"
          quoteeRole="M.S., CCC-SLP"
        />
      </div>
      {/* <div className=" mt-2 grid grid-cols-2 gap-0.5 md:grid-cols-2 lg:mt-0 lg:grid-cols-2">
                  <LogoItem src={<HipaaLogo className="h-28 w-28"/>} />
                  <LogoItem src={<EdLogo className="h-28 w-28"/>} />
                </div> */}
    </div>
  );
};

const LogoItem = ({ src }: any) => (
  <div className="col-span-1 flex justify-center items-center py-8 px-8 ">{src}</div>
);

const ExaminerTierBenefits = () => (
  <>
    <TierBulletsSection
      heading={'Ready to use your free platform?'}
      checkout={true}
      bullets={[
        // {
        //   title: 'Gather information faster from parents, teachers, and nurses with digital questionnaires (available in English/Spanish)',
        //   subtitle: 'Share form link, get email notification when form is completed, view responses and have it automatically feed into your report.',
        // },
        // {
        //   title: 'Add assessment impressions and scores',
        //   sub: 'Have your template and frequently used blurbs in one place and insert into your report with one click',
        //   // subtitle: 'Pre-built evaluations based on best-practices plus the ability to build and save your own templates, all in one place.'
        // },
        // {
        //   title: 'Provide determination and goals',
        //   // sub: 'Digital parent, teacher, and health questionnaires that automatically feed into your report template.',
        //   // subtitle: 'Digital parent, teacher, and health questionnaires that automatically feed into your report template.'
        // },
        // {
        //   title: 'Customize your report in an editable doc',
        //   // sub: 'Have more time to focus on your students',
        //   // subtitle: 'Automatically pull in the referral information and evaluation impressions into your report with the click of a button.'
        // },

        {
          title: 'Multi-lingual Digital Intake Forms (available in English/Spanish)',
          sub: 'Share digital questionnaires in English or Spanish with parents, teachers, and school nurses.',
          subtitle:
            'Share digital questionnaires in English or Spanish with parents, teachers, and school nurses.',
        },
        {
          title: 'Language Proficiency Estimate',
          sub: 'Language exposure is automatically calculated to help you plan your assessment.',
          subtitle:
            'Language exposure is automatically calculated to help you plan your assessment.',
        },
        {
          title: 'Interactive Goal Bank (in English/Spanish)',
          sub: 'Goals available in English and Spanish that instantly meet S.M.A.R.T. (Specific, Measurable, Achievable, Relevant, Time-bound) criteria by adding effort (e.g., minimal) and opportunity (e.g., 70%) requirements.',
          subtitle:
            'Goals available in English and Spanish that instantly meet S.M.A.R.T. (Specific, Measurable, Achievable, Relevant, Time-bound) criteria by adding effort (e.g., minimal) and opportunity (e.g., 70%) requirements.',
        },
      ]}
    />
  </>
);

const TierBulletsSection = ({
  heading,
  bullets,
  hasIcon = true,
  disclaimer = false,
  checkout,
}: any) => (
  <div className={disclaimer ? ' py-2 px-6 ' : `pt-6 pb-8 ${checkout ? '' : 'px-6'}`}>
    {heading && heading !== '' && <h1 className="text-6xl font-semibold text-stai-1">{heading}</h1>}
    <ul className={disclaimer ? '' : 'mt-6 space-y-3'}>
      {bullets.map((feature) => (
        <TierBullet feature={feature} key={`${heading}-${feature.title}`} hasIcon={hasIcon} />
      ))}
    </ul>
  </div>
);

const TierBullet = ({ feature, hasIcon = true }: any) => (
  <li className="flex space-x-3">
    {hasIcon && (
      <span className="pt-1">
        <CheckIcon className="flex-shrink-0 h-5 w-5 text-primary-600" aria-hidden="true" />
      </span>
    )}
    <span className="prose prose-primary max-w-none text-xl" title={feature.sub && feature.sub}>
      {feature.disclaimer && (
        <small className=" block prose prose-primary max-w-none text-sm ">
          {feature.disclaimer}
        </small>
      )}
      {feature.title && feature.title}
      {feature.subtitle && (
        <small className=" block prose prose-primary max-w-none text-sm">{feature.subtitle}</small>
      )}
    </span>
  </li>
);
